import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainContainer: {
    backgroundImage: `url("./assests/footer.png")`,
    backgroundRepeat: 'no-repeat',
    minHeight: '870px',
    backgroundSize: '100% auto',
    backgroundPosition: 'center top',
    '@media (min-width: 1700px)': {
      backgroundSize: '100% 100%',
    },
    '@media (max-width: 1397px)': {
      backgroundColor: '#250f8a',
      backgroundImage: 'none',
    },
    '& .footerContent': {
      paddingTop: '29rem',
      '@media (max-width: 375px)': {
        paddingTop: '10rem',
      },
      '@media (max-width: 1397px)': {
        paddingTop: '10rem',
      },
      '& .footerGrid': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        '& .Grid2 .Services': {
          paddingLeft: 80,
          '@media (max-width: 599px)': {
            paddingLeft: 0,
          },
        },
        '& h1': {
          color: 'white',
          fontSize: 28,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.47,
          marginTop: 8,
        },
        '& .footerItems': {
          padding: 0,
          '& li': {
            color: 'white',
            cursor: 'pointer',
            marginTop: 25,
            listStyle: 'none',
            fontSize: 18,
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 400,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.3,
            '& a': {
              textDecoration: 'none',
              color: 'white',
            },
          },
        },
        '& .FooterLogo': {
          '& p': {
            paddingTop: '2.2rem',
            fontSize: 18,
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 400,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.3,
            color: '#ffff',
            '@media (max-width: 375px)': {
              paddingTop: 0,
            },
          },
        },
      },
      '& .footerAddress': {
        '& p': {
          color: '#ffff',
          marginTop: 30,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          '& span': {
            display: 'block',
            marginTop: 5,
          },
        },
        '& .Footericons': {
          cursor: 'pointer',
          '& .MuiSvgIcon-root': {
            fill: '#fff',
            marginRight: 10,
            width: 30,
            height: 31,
          },
          '& img': {
            width: '12%',
          },
        },
      },
    },
    '& .footerPara': {
      textAlign: 'center',
      paddingTop: '4rem',
      color: 'white',
      marginTop: 0,
      fontSize: 18,
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 0.3,
      '& span': {
        color: '#250f8a',
      },
    },
  },
}));

export default useStyles;
