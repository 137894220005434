import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    '& .projects': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (max-width: 600px)': {
        paddingTop: '4rem',
      },
      '& h1': {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 600,
        color: '#250f8a',
        '@media (max-width: 630px)': {
          fontSize: 30,
        },
        '@media (max-width: 500px)': {
          fontSize: 20,
        },
      },
      '& h2': {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 600,
        paddingLeft: 10,
        color: '#250f8a',
        '@media (max-width: 630px)': {
          fontSize: 30,
        },
        '@media (max-width: 500px)': {
          fontSize: 20,
        },
        '@media (max-width: 375px)': {
          fontSize: 17,
        },
      },
      '& p': {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 400,
        backgroundColor: '#250f8a',
        color: 'white',
        padding: '7px 11px',
        borderRadius: 5,
        cursor: 'pointer',
        boxShadow: '2px 2px #b1daf9',
        '@media (max-width: 630px)': {
          fontSize: 20,
        },
        '@media (max-width: 580px)': {
          fontSize: 18,
        },
        '@media (max-width: 375px)': {
          fontSize: 15,
        },
      },
      '& span': {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 400,
        fontSize: 50,
        color: '#250f8a',
        marginTop: '-5px',
        '@media (max-width: 630px)': {
          fontSize: 40,
        },
      },
    },
  },
}));

export default useStyles;
