import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import FooterStyle from './FooterStyle';
import CONSTANTS from '../../constants';

const Footer = () => {
  const classes = FooterStyle();

  return (
    <div className={classes.mainContainer}>
      <Container fixed>
        <div className="footerContent">
          <Grid container spacing={10} className="footerGrid">
            <Grid item lg={3} md={4} sm={6} xs={12} className="Grid1">
              <div className="FooterLogo">
                <img src={CONSTANTS.url('/assests/logo.png')} alt="logo" />
                <p>
                  Cantem la solution agile et sur-mesure, pour les grands comptes, PME,
                  micro-entreprises et particuliers. Nos solutions s'adaptent à vous, et non
                  l'inverse.
                </p>
              </div>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12} className="Grid3">
              <div className="Propos">
                <h1>À PROPOS</h1>
                <ul className="footerItems">
                  <li>
                    <AnchorLink href="#ABOUT">Cantem</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#SPECIALITES">Services</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#PORTIFLIO">PortFolio</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#CONTACT">Contact</AnchorLink>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12} className="Grid4">
              <div className="footerAddress">
                <h1>ADRESSE</h1>
                <p>
                  14 Avenue Jean Monnet
                  <br />
                  issy Les Moulineaux
                </p>
                <p>
                  Contact@cantem.fr
                  <span>0658280016</span>
                </p>
                <div className="Footericons">
                  <a href="https://www.facebook.com/Canteminc/">
                    <FacebookIcon />
                  </a>
                  <a href="https://www.linkedin.com/company/cantem-technology/">
                    <LinkedInIcon />
                  </a>
                  <a href="https://www.codeur.com/-cantem-technology">
                    <img src={CONSTANTS.url('/assests/unnamed.png')} alt="unnamed" />
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="footerPara">
            <p>
              @Designed by <span>Cantem</span> all right reserved
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
