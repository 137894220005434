import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from '@material-ui/core/Container';
import WebModalStyle2 from './WebModalStyle2';
import CONSTANTS from '../../../../constants';

const WebModal = () => {
  const classes = WebModalStyle2();
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };
  return (
    <Container fixed className={classes.Container}>
      <div className="webContent">
        <h1>PlaceMaisonDeRetraite</h1>
        <div className="textWrapper">
          <a style={{ textDecoration: 'inherit' }} href="https://placemaisonderetraite.fr">
            "placemaisonderetraite.fr"
          </a>
          <p>
            Solution adaptée pour les Familles et les Établissements de soins spécialisés, dédiée
            aux personnes agées
          </p>
          <p>
            Particuliers :
            <span>
              Trouvez les places disponibles dans les Maisons de Retraite proches de chez vous et
              recevez des alertes correspondants à votre recherche.
            </span>
          </p>
          <p>
            Établissement :
            <span>Donnez de la visibilité a votre établissement et gérez votre agenda !</span>
          </p>
        </div>
        <Slider {...settings} className="imageSlicker" style={{ width: '100%' }}>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Maison de retrait 4.png')} alt="1" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Maison de retrait1.png')} alt="2" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Maison de retrait2.png')} alt="3" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Maison de retrait3.png')} alt="4" />
          </div>
        </Slider>
      </div>
    </Container>
  );
};

export default WebModal;
