import React, { useState } from 'react';
import Header from './components/Header/Header';
import Specialties from './components/Section/Specialties/Specialties';
import Methode from './components/Section/Methode/Methode';
import AboutUs from './components/Section/AboutUs/AboutUs';
import Team from './components/Section/Team/Team';
import Testimonials from './components/Section/Testimonials/Testimonials';
import ContactForm from './components/Section/ContactForm/ContactForm';
import Footer from './components/Footer/Footer';
import PortFolio from './components/Section/PortFolio/PortFolio';
import AppStyle from './AppStyle';

const App = () => {
  const classes = AppStyle();
  const [opendropdown, setOpendropdown] = useState(false);
  return (
    <div className={classes.container}>
      <Header />
      <Specialties />
      <Methode />
      <PortFolio />
      <div className="projects">
        <h1>Et bien d'autres encore</h1>
        <span>&#40;</span>
        <p>32</p>
        <h2>autres projets !</h2>
        <span>&#41;</span>
      </div>
      <AboutUs />
      <Team />
      <Testimonials />
      <ContactForm opendropdown={opendropdown} setOpendropdown={setOpendropdown} />
      <Footer />
    </div>
  );
};

export default App;
