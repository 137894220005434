import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuIcon from '@material-ui/icons/Menu';
import CONSTANTS from '../../constants';
import HeaderStyle from './HeaderStyle';

const Header = () => {
  const [toggle, setToggle] = useState(0);
  const classes = HeaderStyle();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.NavItems}>
          <div className="Headerlogo">
            <img src={CONSTANTS.url('/assests/topRight.png')} alt="" />
          </div>
          <ul className={toggle === 1 ? 'NavLists open' : 'NavLists'}>
            <li>
              <AnchorLink href="#SPECIALITES">SPECIALITÉS</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#PORTIFLIO">PORTFOLIO</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#TEAM">TEAM</AnchorLink>
            </li>
            <li className="Navbtn">
              <AnchorLink href="#CONTACT">VOTRE PROJET</AnchorLink>
            </li>
          </ul>
          <div className="menu-icon">
            <MenuIcon
              onClick={() => {
                setToggle(!toggle);
              }}
            />
          </div>
        </div>
        <Container fixed className="ContentContainer">
          <div className="Headercontent">
            <h1>
              Le digital,
              <span>sur-mesure !</span>
            </h1>
            <h2>
              Pour votre start-up,
              <span>business & vous.</span>
            </h2>
            <p>
              <strong>Cantem</strong>, la solution <strong>agile</strong> et{' '}
              <strong>sur-mesure</strong>. Grands comptes, PME, micro-entreprises et particuliers,
              nos solutions s'adaptent à vous et non l'inverse.
            </p>
            <button type="button" className="ContentBtn">
              <AnchorLink href="#METHOD">Découvrir</AnchorLink>
            </button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
