import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from '@material-ui/core/Container';
import IaModalStyle from './IaModalStyle';
import CONSTANTS from '../../../../constants';

const IaModal = () => {
  const classes = IaModalStyle();
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };
  return (
    <Container fixed className={classes.Container}>
      <div className="webContent">
        <h1>Nexity</h1>
        <p>"Nexity, Projet Call Center"</p>
        <p>
          Cantem au seins de Nexity : Call Center, a managé et créé une Solution Machine Learning à
          destination des call-centers, afin d'optimiser les ventes et d'automatiser les appels aux
          prospects les plus adaptés à chaque produits
        </p>
        <Slider {...settings} className="imageSlicker" style={{ width: '100%' }}>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Nexity1.jpg')} alt="1" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Nexity2.png')} alt="2" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Nexity3.png')} alt="3" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Nexity4.jpg')} alt="4" />
          </div>
        </Slider>
      </div>
    </Container>
  );
};

export default IaModal;
