import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TeamStyle from './TeamStyle';
import CONSTANTS from '../../../constants';

const Team = () => {
  const [layer, setlayer] = useState(0);
  const classes = TeamStyle();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={classes.TeamHeading} onClick={() => setlayer(0)} id="TEAM">
        <Container fixed>
          <Grid container spacing={3}>
            <Grid item md={6} />
            <Grid item md={6} className="Heading">
              <h1>Notre team</h1>
              <h3>Un accompagnement personnalisé</h3>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={classes.root} onClick={() => setlayer(0)}>
        <Container fixed className="ContentContainer">
          <Grid container spacing={3}>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="team"
              onClick={e => {
                e.stopPropagation();
                setlayer(1);
              }}
            >
              <div className="image">
                <img src={CONSTANTS.url('/assests/10.png')} alt="10" width="100%" />
                <div className={layer === 1 ? 'description active' : 'description'}>
                  <span>
                    <h3>"Le grand manitou !"</h3>
                    Décisionnaire stratégique, et lead dev de la partie "Web/App" ainsi que
                    "Progiciel" chez Cantem Seif cumule, a lui seul, plus de 10 ans d'experience
                    dans le domaine du developpement informatique. Il anticipera vos besoins et sera
                    votre interlocuteur privilégié sur la partie technique, tout au long du projet.
                  </span>
                </div>
              </div>
              <div className="SubHeading">
                <h4>Seif Habbachi</h4>
                <p>CEO</p>
              </div>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="team"
              onClick={e => {
                e.stopPropagation();
                setlayer(2);
              }}
            >
              <div className="image">
                <img src={CONSTANTS.url('/assests/11.png')} alt="11" width="100%" />
                <div className={layer === 2 ? 'description active' : 'description'}>
                  <span>
                    <h3>"Le docteur Data-Science"</h3>
                    Exceptionnel "Cerveau" chez Cantem, Jacques est le spécialiste de la R&D mais
                    également le responsable de la branche "Machine Learning". Maniant, à lui seul,
                    9 différents languages de programmation, il est sans conteste le "Mage noir" de
                    la Data Science chez Cantem !
                  </span>
                </div>
              </div>
              <div className="SubHeading">
                <h4>Jacques Perrin</h4>
                <p>CTO</p>
              </div>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="team"
              onClick={e => {
                e.stopPropagation();
                setlayer(3);
              }}
            >
              <div className="image">
                <img src={CONSTANTS.url('/assests/12.png')} alt="12" width="100%" />
                {layer === 3 && (
                  <div className={layer === 3 ? 'description active' : 'description'}>
                    <span>
                      <h3>"Le Couteau Suisse"</h3>
                      <p>
                        Timon Prata est notre spécaliste Front & Design, Aussi bien sur
                        l'application, projet Web ou Progiciel. Notre chère touche-à-tout a, en
                        moins de 4 ans d'experience, déjà plusieurs dizaines de projets à son actif.
                      </p>
                      <p>
                        Spécialiste des langages et technologies s'articulants autour du JS, il
                        manit également une véritable palette de skills qu'il saura mettre à votre
                        profit tout au long de votre projet.
                      </p>
                    </span>
                  </div>
                )}
              </div>
              <div className="SubHeading">
                <h4>Timon prata</h4>
                <p>CBO</p>
              </div>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className="team"
              onClick={e => {
                e.stopPropagation();
                setlayer(4);
              }}
            >
              <div className="image">
                <img src={CONSTANTS.url('/assests/13.png')} alt="13" width="100%" />
                <div className={layer === 4 ? 'description active' : 'description'}>
                  <span>
                    <h3>"Project manager"</h3>
                    Responsable de la communication et de la partie commercial, Jean-Patrice est
                    également project manager au sein de Cantem, Responsable de la "Big Picture" de
                    votre projet, il gèrera : Équipes, Prestataires, Sprints & Réunions en tout
                    genre sur toute la durée de création de votre solution.
                  </span>
                </div>
              </div>
              <div className="SubHeading">
                <h4>Jean-Patrice</h4>
                <p>COO</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Team;
