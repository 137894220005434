import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '6rem',
    '& .MuiGrid-container': {
      marginTop: -61,
      '@media (max-width: 959px)': {
        marginTop: 0,
      },
    },
    '& .GridContainer': {
      backgroundImage: `url("./assests/portBack.png")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left 70%',
      backgroundSize: '70% auto',
      '@media (max-width: 959px)': {
        backgroundImage: 'none',
      },
      '& .image': {
        '@media (max-width: 960px)': {
          justifyContent: 'center',
        },
        '& .img': {
          '@media (max-width: 960px)': {
            padding: '0 !important',
            margin: '0 !important',
            maxWidth: '100% !important',
          },
        },
        '& h1': {
          '@media (max-width: 960px)': {
            bottom: 'initial !important',
            top: 'initial !important',
            left: 'initial !important',
            margin: '0 auto !important',
            padding: '0 !important',
          },
        },
        '& img': {
          '@media (max-width: 960px)': {
            maxWidth: '100% !important',
          },
        },
        '& .inner': {
          '@media (max-width: 960px)': {
            bottom: 'initial !important',
          },
        },
        '& .button': {
          '@media (max-width: 960px)': {
            bottom: 'initial !important',
            position: 'relative',
            top: 600,
          },
          '@media (max-width: 599px)': {
            position: 'relative',
            top: 500,
          },
          '@media (max-width: 418px)': {
            position: 'relative',
            top: 429,
          },
          '@media (max-width: 344px)': {
            position: 'relative',
            top: 410,
          },
        },
      },
    },
    '& .inner': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      '& .image': {
        '& .inner': {
          display: 'flex',
          '& .img': {
            position: 'relative',
            cursor: 'pointer',
            // '& .description': {
            //   position: 'absolute',
            //   top: 0,
            //   left: 0,
            //   width: '100%',
            //   height: '100%',
            //   backgroundColor: 'rgb(50, 44, 147, 0.8)',
            //   fontFamily: 'Source Sans Pro, sans-serif',
            //   fontWeight: 400,
            //   color: 'white',
            //   display: 'flex',
            //   display: 'none',
            //   alignItems: 'center',
            //   cursor: 'pointer',
            //   borderRadius: 9,
            //   '& p': {
            //     textAlign: 'center',
            //     padding: 10,
            //   },
            // },
          },
        },
        '& h1': {
          marginLeft: 'auto',
          position: 'relative',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          color: '#250f8a',
          fontSize: '50px',
          zIndex: '2',
          '@media (max-width: 959px)': {
            fontSize: 40,
          },
        },
        '& img': {
          width: '100%',
          maxWidth: '430px',
        },
        '&.img1': {
          '& h1': {
            position: 'relative',
            bottom: '-120px',
            left: 33,
          },
          '& .img': {
            marginLeft: 'auto',
            marginRight: '50px',
            '& img': {
              display: 'block',
            },
          },
        },
        '&.img3': {
          '& h1': {
            position: 'relative',
            top: '-123px',
            paddingRight: '280px',
          },
          '& .img': {
            maxWidth: '320px',
            marginLeft: 'auto',
            marginRight: '50px',
            '& img': {
              display: 'block',
            },
          },
        },
        '&.img2': {
          '& .inner': {
            position: 'relative',
            bottom: '-259px',
          },
          '& h1': {
            marginLeft: '0',
            marginRight: 'auto',
            paddingLeft: '204px',
            position: 'relative',
            top: '-91px',
          },
          '& .img': {
            width: '100%',
            maxWidth: '340px',
            '& img': {
              display: 'block',
              maxWidth: '100%',
            },
          },
          '& .button': {
            width: '150px',
            backgroundColor: '#250f8a',
            padding: '12px 11px 12px 11px',
            borderRadius: 15,
            position: 'relative',
            color: 'white',
            bottom: -60,
            '& .MuiButton-label': {
              '& a': {
                textDecoration: 'none',
                color: '#fff',
              },
            },
          },
        },
      },
      '& .heading': {
        '& h1': {
          fontSize: 50,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.83,
          textAlign: 'center',
          marginBottom: 0,
          color: '#250f8a',
        },
        '& h3': {
          fontSize: 28,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.47,
          textAlign: 'center',
          marginTop: 10,
          color: '#250f8a',
          marginBottom: 0,
        },
      },
    },
  },
}));

export default useStyles;
