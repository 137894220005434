import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ContactFormStyle from './ContactFormStyle';

const ContactForm = ({ opendropdown, setOpendropdown }) => {
  const [state, setState] = useState({
    nom: '',
    telephone: '',
    email: '',
    project: '',
    message: '',
    file: null,
    mailSent: false,
    error: null,
  });
  const classes = ContactFormStyle();
  const dropdownlist = ['Site-Web', 'Applications', 'Machine Learning', 'Logiciel', 'Autre'];

  const selectImages = event => {
    const fileSelected = event.target.files[0];

    if (fileSelected.size < 5000000) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setState({
          ...state,
          mailSent: false,
          file: fileSelected,
        });
      };
      reader.readAsDataURL(fileSelected);
    } else {
      alert('Votre fichier est trop volumineux, maximum 5Mo');
    }
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    const { nom, telephone, email, project, message, file } = state;

    let fileName = '';
    if (file) {
      const data = new FormData();

      data.append('file', file, file.name);
      const uploadOptions = {
        method: 'POST',
        body: data,
      };

      const uploadResp = await fetch('https://34.ip-54-37-157.eu:5555/upload', uploadOptions);
      const uploaded = await uploadResp.json();

      if (uploaded.success) fileName = uploaded.filename;
    }

    const reqOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nom,
        telephone,
        email,
        project,
        message,
        fileName,
      }),
    };
    await fetch('https://34.ip-54-37-157.eu:5555/contact-form', reqOptions);

    setState({ ...state, file: null, mailSent: true });
  };

  return (
    <Container fixed id="CONTACT" onClick={() => setOpendropdown(false)}>
      <div className={classes.FormContent}>
        <div className="FormHeading">
          <h1>Nous contacter</h1>
        </div>
        <div className="contactForm">
          <form className={classes.root} noValidate autoComplete="off">
            <div className="Labelfield">
              <div className="field">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Nom"
                  variant="outlined"
                  onChange={e => setState({ ...state, mailSent: false, nom: e.target.value })}
                />
              </div>
            </div>
            <div className="Labelfield">
              <div className="field">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Telephone"
                  variant="outlined"
                  onChange={e => setState({ ...state, mailSent: false, telephone: e.target.value })}
                />
              </div>
            </div>
            <div className="Labelfield">
              <div className="field">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onChange={e => setState({ ...state, mailSent: false, email: e.target.value })}
                />
              </div>
            </div>
            <div className="Labelfield">
              <div className="field">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Type de projet</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="type de projet"
                    open={opendropdown}
                    value={state.project}
                    onOpen={() => setOpendropdown(!opendropdown)}
                    onChange={e => setState({ ...state, mailSent: false, project: e.target.value })}
                  >
                    {dropdownlist.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="Labelfield lf-100">
              <div className="field">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Posez votre question ou décrivez votre projet…"
                  multiline
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  onChange={e => setState({ ...state, mailSent: false, message: e.target.value })}
                />
              </div>
            </div>
            <div className="Labelfield lf-100">
              <div className="field">
                <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                  Envoyer
                </Button>
                <div className="AttachmentIcon">
                  <input type="file" id="upload_a_file" onChange={selectImages} />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="upload_a_file"> </label>
                </div>
              </div>
            </div>
            <div style={{ alignItem: 'center', marginRight: 'auto', marginLeft: 'auto' }}>
              {state.mailSent && (
                <div style={{ alignSelf: 'center' }}>Votre message a bien été envoyé.</div>
              )}
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default ContactForm;
