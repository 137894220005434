import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from '@material-ui/core/Container';
import AppModalStyle2 from './AppModalStyle2';
import CONSTANTS from '../../../../constants';

const AppModal = () => {
  const classes = AppModalStyle2();
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };
  return (
    <Container fixed className={classes.Container}>
      <div className="webContent">
        <h1>Pop In Shop</h1>
        <div className="appText">
          <p>"Pop In Shop Sur App Store et Play Store"</p>
          <p>Rapprochez-vous vraiment de vos commerces de proximité</p>
          <p>
            Avec Pop in Shop,
            <ul className="applists">
              <li>Je cherche un produit auprès de mes commerces favoris </li>
              <li>Je prépare mon panier et je choisis mes horaires de passage </li>
              <li>Je récupère mes produits en boutique et je paie avec Pop in Shop </li>
            </ul>
          </p>
        </div>
        <Slider {...settings} className="imageSlicker" style={{ width: '100%' }}>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Pop In Shop 1.jpg')} alt="1" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Pop In Shop 2.jpg')} alt="2" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Pop In Shop 3.jpg')} alt="3" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Pop In Shop 4.jpg')} alt="4" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Pop In Shop 5.jpg')} alt="5" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Pop In Shop 6.jpg')} alt="6" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Pop In Shop 7.jpg')} alt="7" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Pop In Shop 8.jpg')} alt="8" />
          </div>
        </Slider>
      </div>
    </Container>
  );
};

export default AppModal;
