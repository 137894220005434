import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import PortFolioStyle from './PortFolioStyle';
import PortFolioGrid from './PortFolioGrid';
import PortFolioGrid2 from './PortFolioGrid2';

const PortFolio = () => {
  const [toggle, toggleMore] = useState(false);
  const classes = PortFolioStyle();

  return (
    <Container fixed className={classes.root}>
      <div className="inner" id="PORTIFLIO">
        <div className="heading">
          <h1>Notre Portfolio</h1>
          <h3>Un petit aperçu de nos travaux</h3>
          <PortFolioGrid
            toggle={toggle}
            toggleMore={toggleMore}
            hideButton={toggle}
            title="Voir plus"
            id="port1"
          />
          {toggle && (
            <PortFolioGrid2 toggle={toggle} toggleMore={toggleMore} title="Voir moins" id="port2" />
          )}
        </div>
      </div>
    </Container>
  );
};

export default PortFolio;
