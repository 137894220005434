import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  Container: {
    zIndex: 10,
    padding: '25px 40px',
    objectFit: 'contain',
    borderRadius: 15,
    boxShadow: '2px 2px 14px 0 rgba(0, 26, 48, 0.09)',
    backgroundColor: '#ffffff',
    '& .webContent': {
      padding: '0px 100px',
      '@media (max-width: 630px)': {
        padding: 0,
      },
      '& h1': {
        textAlign: 'center',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 600,
        fontSize: 45,
        letterSpacing: 0.75,
        color: '#250f8a',
        margin: 0,
        '@media (max-width: 630px)': {
          fontSize: 30,
          margin: 0,
        },
        '@media (max-width: 580px)': {
          fontSize: 25,
        },
      },
      '& p': {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 400,
        fontSize: 18,
        color: '#250f8a',
        paddingLeft: '2rem',
        '@media (max-width: 630px)': {
          fontSize: 15,
          marginTop: 5,
        },
        '@media (max-width: 580px)': {
          fontSize: 18,
        },
        '@media (max-width: 375px)': {
          fontSize: 16,
        },
      },
      '& .imageSlicker': {
        outline: 'none',
        '& .imageWrapper': {
          display: 'flex !important',
          justifyContent: 'center',
          outline: 'none',
          '& img': {
            borderRadius: 15,
            display: 'block',
            height: '500px',
            '@media (max-width: 630px)': {
              height: '200px',
            },
            '@media (max-width: 580px)': {
              height: '137px',
            },
            '@media (max-width: 400px)': {
              height: '120px',
              borderRadius: 10,
            },
            '@media (max-width: 375px)': {
              height: '120px',
              borderRadius: 10,
            },
          },
        },
        '& .slick-next:before, .slick-prev:before': {
          color: '#250f8a',
        },
      },
    },
  },
}));

export default useStyles;
