import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from '@material-ui/core/Container';
import WebModalStyle from './WebModalStyle';
import CONSTANTS from '../../../../constants';

const WebModal = () => {
  const classes = WebModalStyle();
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };
  return (
    <Container fixed className={classes.Container}>
      <div className="webContent">
        <h1>Gwencloud</h1>
        <p>
          "www.gwencloud.com" Le mining de cryptomonnaie enfin à votre portée. " Que vous soyez un
          mineur professionnel ou un particulier, si vous souhaitez produire des bitcoins pour faire
          des marges importantes à la revente, nous avons ce que vous recherchez. "
        </p>
        <Slider {...settings} className="imageSlicker" style={{ width: '100%' }}>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/GWencloud 2.png')} alt="1" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Gwencloud1.png')} alt="2" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/GWencloud3.png')} alt="3" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Gwencloud4.png')} alt="4" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/GWencloud5.png')} alt="5" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Gwencloud6.png')} alt="6" />
          </div>
        </Slider>
      </div>
    </Container>
  );
};

export default WebModal;
