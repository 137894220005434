const constants = {
  // URL: 'http://localhost:3000',
  // URL: 'http://192.168.0.113:3000',
  // URL: 'http://www5.sprysol.com/marvel',
  URL: 'https://cantem.fr',
  NAME: 'cantem',
  url(append = '') {
    return this.URL + append;
  },
};

export default constants;
