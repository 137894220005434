import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from '@material-ui/core/Container';
import IaModalStyle2 from './IaModalStyle2';
import CONSTANTS from '../../../../constants';

const IaModal = () => {
  const classes = IaModalStyle2();
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };
  return (
    <Container fixed className={classes.Container}>
      <div className="webContent">
        <h1>Nexity2</h1>
        <p>"Nexity Solution Crédit"</p>
        <p>
          Cantem, au sein de Nexity Solution Crédit, a entammé la création d'un algorithme
          permettant d'optimiser les demandes de crédit des clients de la multinationale, En effet,
          il permet de mieux diriger le dossier, de trouver les meilleurs interlocuteurs banquaire
          et d'accélérer le processus interne de Nexity
        </p>
        <Slider {...settings} className="imageSlicker" style={{ width: '100%' }}>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/NexitySC1.jfif')} alt="1" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/NexitySC2.jfif')} alt="2" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/ext.jfif')} alt="3" />
          </div>
        </Slider>
      </div>
    </Container>
  );
};

export default IaModal;
