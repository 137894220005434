import React from 'react';
import Container from '@material-ui/core/Container';
import Slider from 'react-slick';
import CONSTANTS from '../../../constants';
import TestimonialsStyle from './TestimonialsStyle';

const Testimonials = () => {
  const classes = TestimonialsStyle();
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={classes.Container}>
      <Container fixed className="ContentContainer">
        <div className="TestimonialsContainer">
          <h1>
            Ne nous croyez
            <span>pas sur parole !</span>
          </h1>
          <div className="TestimonialsGrid">
            <div className="blocks">
              <Slider {...settings}>
                <div className="block">
                  <div className="innerContent">
                    <img alt="Pop in Shop" src={CONSTANTS.url('/assests/bitmap.png')} />
                    <h2>Rachida Taleb</h2>
                    <h3>Product Management, Datapart</h3>
                    <p>
                      Cantem a su nous accompagner avec brio tout le long de la conception de notre
                      App, ils ont réponses à toutes problématiques techniques. Ils ont su prendre
                      les meilleures décisions tout en restant en phase avec nos ambitions. Je
                      recommande !
                    </p>
                  </div>
                </div>
                <div className="block">
                  <div className="innerContent">
                    <img alt="LOOKING FOR" src={CONSTANTS.url('/assests/bitmap4.png')} />
                    <h2>Raphael Serafyn</h2>
                    <h3>Gérant, chez LOOKING FOR</h3>
                    <p>
                      Cantem d’un calme olympien donnent beaucoup de leur personne, ils comprennent
                      parfois mieux les projets que les auteurs, ce qui leur permet de reformuler,
                      d’anticiper et de faire gagner énormément de temps, tant dans la prise de
                      décision que pour l’exécution.
                    </p>
                  </div>
                </div>
                <div className="block">
                  <div className="innerContent">
                    <img alt="DIGITAL PEOPLE" src={CONSTANTS.url('/assests/bitmap3.png')} />
                    <h2>Amir Hashemi</h2>
                    <h3>CTO, DIGITAL PEOPLE</h3>
                    <p>
                      I had the occasion and pleasure of working with Seif on several javascript/
                      nodejs projects. He is a professional in his domain, well organized and highly
                      engaged.
                    </p>
                  </div>
                </div>
                <div className="block">
                  <div className="innerContent">
                    <img alt="WIZARDS TECHNOLOGIES" src={CONSTANTS.url('/assests/bitmap2.png')} />
                    <h2>Gael Coat</h2>
                    <h3>CEO, WIZARDS TECHNOLOGIES</h3>
                    <p>
                      Professionnels pointus, j’ai eu l’occasion de collaborer avec Cantem sur
                      différents projets et j’ai toujours trouvé nos échanges aussi efficaces
                      qu’agréables. Excellents techniciens, ils savent mettre à profit leurs
                      compétences sur des problématiques métiers complexes et s’intégrer dans des
                      écosystèmes techniques et/ou business hétérogènes. Je recommande
                      chaleureusement.
                    </p>
                  </div>
                </div>
                <div className="block">
                  <div className="innerContent">
                    <img alt="Malguy technology" src={CONSTANTS.url('/assests/bitmap5.png')} />
                    <h2>Quentin</h2>
                    <h3>Directeur de Malguy technology</h3>
                    <p>
                      J'ai eu l'honneur de collaborer avec Cantem sur la création d'une applications
                      & Site web from scratch. Ils ont su être force de proposition et d'un grand
                      savoir faire. Je recommande vivement !
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Testimonials;
