import React from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import MethodeStyle from './MethodeStyle';
import CONSTANTS from '../../../constants';

const Methode = () => {
  const downloadUrl = 'https://cantem.fr/CANTEM_IN_NUTSHELL.pdf';

  const classes = MethodeStyle();
  return (
    <div className={classes.root} id="METHOD">
      <Container fixed className="ContentContainer">
        <div className="Heading">
          <h1>Méthode</h1>
          <h3>Une solution adaptée à vos objectifs</h3>
        </div>
        <div className="sideContent">
          <div className="image">
            <img src={CONSTANTS.url('/assests/2.png')} alt="" />
          </div>
          <div className="text">
            <h2>Méthode agile, sauce Cantem !</h2>
            <p className="para1">
              C’est le pivot de la roue des projets Cantem ! Ces méthodes présentent un avantage
              majeur : elles intègrent le client dans le groupe projet et favorisent le dialogue
              dans le but de mettre au point une solution entièrement fonctionnelle et totalement
              adaptée à chaque client.
            </p>
            <p className="para2">
              Le document ci-dessous, vous expliquera plus en détail le fonctionnement de celle-ci,
              au sein de l’aventure Cantem !
            </p>
            <a style={{ textDecoration: 'none' }} href={downloadUrl}>
              <Button className="btn" variant="contained" color="primary" disableElevation>
                Télécharger
              </Button>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Methode;
