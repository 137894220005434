import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from '@material-ui/core/Container';
import AppModalStyle from './AppModalStyle';
import CONSTANTS from '../../../../constants';

const AppModal = () => {
  const classes = AppModalStyle();
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };
  return (
    <Container fixed className={classes.Container}>
      <div className="webContent">
        <h1>IDEEL</h1>
        <div className="appText">
          <p>"https://play.google.com/store/apps/details?id=com.ideel"</p>
          <p>L'outil de gestion d'abonnement et de forfait en tout genre, par excellence</p>
          <ul className="applists">
            <li>1. Importez tous vos abonnements</li>
            <li>2. Reprenez le contrôle sur vos échéances</li>
            <li>3. Dites adieu aux tâches administratives</li>
          </ul>
        </div>
        <Slider {...settings} className="imageSlicker" style={{ width: '100%' }}>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Ideel1.jpg')} alt="1" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Ideel2.jpg')} alt="2" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Ideel3.jpg')} alt="3" />
          </div>
          <div className="imageWrapper">
            <img src={CONSTANTS.url('/assests/images/Ideel5.jpg')} alt="4" />
          </div>
        </Slider>
      </div>
    </Container>
  );
};

export default AppModal;
