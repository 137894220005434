import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    backgroundImage: `url("./assests/group-2.png")`,
    backgroundRepeat: 'no-repeat',
    height: '954px',
    backgroundPosition: 'top right',
    objectFit: 'contain',
    '@media (max-width: 1074px)': {
      height: 'auto',
      paddingBottom: 40,
    },
    '@media (max-width: 1192px)': {
      backgroundSize: 'auto 100%',
    },
    '& .ContentContainer': {
      maxWidth: '1130px',
      marginTop: 100,
      '& .Headercontent': {
        '@media (max-width: 698px)': {
          display: 'none',
        },
        '& h1': {
          fontSize: 75,
          color: '#250f8a',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStyle: 'normal',
          lineHeight: 0.87,
          marginTop: 0,
          marginBottom: 18,
          letterSpacing: 1.25,
          '& span': {
            display: 'block',
          },
          '@media (max-width: 820px)': {
            fontSize: 50,
          },
          '@media (max-width: 698px)': {
            color: 'white',
          },
        },
        '& h2': {
          background: '-webkit-linear-gradient(#ef1829, #fea600 )',
          '-webkit-background-clip': 'text',
          ' -webkit-text-fill-color': 'transparent',
          marginBottom: 30,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontSize: 25,
          lineHeight: 0.87,
          marginTop: 0,
          letterSpacing: 1.25,
          fontStyle: 'normal',
          '& span': {
            display: 'block',
            background: '-webkit-linear-gradient(#fea600, #ef1829)',
            '-webkit-background-clip': 'text',
            '  -webkit-text-fill-color': 'transparent',
          },
          '@media (max-width: 820px)': {
            fontSize: 20,
            marginBottom: 40,
          },
          '@media (max-width: 698px)': {
            color: 'white',
          },
        },
        '& p': {
          width: 292,
          fontSize: 16,
          fontWeight: 400,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.27,
          color: '#250f8a',
          marginBottom: 30,
          '@media (max-width: 820px)': {
            fontSize: 14,
          },
          '@media (max-width: 698px)': {
            color: 'white',
          },
        },
        '& .ContentBtn': {
          background: 'linear-gradient(to right, #ef1829 0%,#fea600 40%,#fea600 100%)',
          border: 'none',
          padding: '12px 36px 12px 37px',
          backgroundSize: '200% auto',
          borderRadius: '10px',
          cursor: 'pointer',
          display: 'inline-block',
          '@media (max-width: 820px)': {
            padding: '10px 22px 10px 22px',
          },
          '& a': {
            fontStyle: 'normal',
            fontStretch: 'normal',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 400,
            lineHeight: 'normal',
            textDecoration: 'none',
            color: 'white',
            fontSize: 20,
            letterSpacing: 0.27,
            '@media (max-width: 820px)': {
              fontSize: 16,
            },
          },
        },
      },
    },
  },
  NavItems: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    alignItems: 'center',
    '& .Headerlogo': {
      paddingLeft: 110,
      '@media (max-width: 1192px)': {
        paddingLeft: 0,
      },
      '@media (max-width: 375px)': {
        marginLeft: '-15px',
      },
      '& img': {
        width: 190,
        display: 'block',
      },
    },
    '& .menu-icon': {
      display: 'none',
      '@media (max-width: 820px)': {
        display: 'block',
      },
    },
    '& .NavLists': {
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      '@media (max-width: 1015px)': {
        padding: 0,
      },
      '@media (max-width: 820px)': {
        flexDirection: 'column',
        display: 'none',
        position: 'absolute',
        right: 20,
        top: 60,
        backgroundColor: 'rgba(20,40,123,0.9)',
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: '15px',
        padding: '10px',
      },
      '&.open': {
        '@media (max-width: 820px)': {
          display: 'block',
        },
      },
      '& .Navbtn': {
        backgroundImage: 'linear-gradient(220deg, #9e005d, #f70f47)',
        border: 'none',
        margin: '20px',
        padding: '10px 21px 14px 21px',
        textAlign: 'center',
        backgroundSize: '200% auto',
        color: '#FFF',
        height: '14px',
        cursor: 'pointer',
        display: 'inline-block',
        borderRadius: 15,
        '@media (max-width: 1074px)': {
          margin: '20px 0px 0px 0px',
        },
        '@media (max-width: 820px)': {
          marginTop: '0px',
          padding: '10px 10px 14px 10px',
        },
      },
      '& li': {
        margin: 30,
        color: 'white',
        cursor: 'pointer',
        '@media (max-width: 970px)': {
          marginLeft: 10,
          marginRight: 10,
        },
        '@media (max-width: 1074px)': {
          marginLeft: 8,
          marginRight: 8,
        },
        '@media (max-width: 820px)': {
          margin: '10px',
        },
        '& a': {
          textDecoration: 'none',
          objectFit: 'contain',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          fontSize: 16,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.27,
          textAlign: 'center',
          color: '#ffffff',
        },
      },
    },
  },
}));

export default useStyles;
