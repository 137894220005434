import React from 'react';
import Container from '@material-ui/core/Container';
import SpecialtiesStyle from './SpecialtiesStyle';
import ContentGrid from './ContentGrid/ContentGrid';

const Specialties = () => {
  const classes = SpecialtiesStyle();
  return (
    <div className={classes.Container}>
      <Container fixed className="ContentContainer">
        <div className="MainHeading">
          <h1 id="SPECIALITES">Nos spécialités </h1>
          <h3>De l’idée à l’exécution</h3>
        </div>
        <ContentGrid />
      </Container>
    </div>
  );
};

export default Specialties;
