import React from 'react';
import Container from '@material-ui/core/Container';
import AboutUsStyle from './AboutUsStyle';
import CONSTANTS from '../../../constants';

const Nous = () => {
  const classes = AboutUsStyle();
  return (
    <Container fixed className={classes.ContentContainer} id="ABOUT">
      <div className="AboutContent">
        <div className="AboutText">
          <h1>À propos de nous</h1>
          <h3>Mais Cantem, quesaco ?</h3>
          <p>
            Cantem, c’est l’association de quatre amis et camarades de promotion de l’école 42,
            lancés sur un projet de Machine Learning pour le compte de l’entreprise Nexity via le
            réseau « Start-Up Studio ».
            <br />
            La société et nos quatre mousquetaires ne se sont pas arrêtés là et ont, de suite,
            enchainés la conception de solutions « Tailored » à destination d’entreprises !
          </p>
        </div>
        <div className="Aboutimage">
          <img src={CONSTANTS.url('/assests/1.png')} alt="1" />
        </div>
      </div>
    </Container>
  );
};
export default Nous;
