import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  FormContent: {
    '& .FormHeading': {
      '& h1': {
        fontSize: 50,
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.83,
        color: '#250f8a',
        textAlign: 'center',
      },
    },
    '& .contactForm': {
      display: 'flex',
      width: '100%',
      maxWidth: '650px',
      margin: 'auto',
      padding: '20px',
      '@media (max-width: 375px)': {
        padding: 0,
      },
      '& form': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      '& .Labelfield': {
        width: '50%',
        '&.lf-100': {
          width: '100%',
        },
        '& .field': {
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          '& .AttachmentIcon': {
            '& #upload_a_file': {
              display: 'none',
            },
            '& label': {
              backgroundImage: `url("./assests/attachment.png")`,
              backgroundRepeat: 'no-repeat',
              textIndent: '-10000px',
              overflow: 'hidden',
              display: 'block',
              width: '50px',
              height: '50px',
              cursor: 'pointer',
              margin: '0 0 0 15px',
            },
          },
          '& .MuiFormControl-root': {
            width: '100%',
          },
          '& .MuiButton-contained': {
            backgroundImage: 'linear-gradient(90deg, #f70f47, #9e005d)',
            padding: '10px 41px',
            borderRadius: 15,
            textTransform: 'none',
            fontSize: 17,
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 400,
          },
          '& .MuiSvgIcon-root': {
            marginLeft: 3,
            fill: '#250f8a',
          },
        },
      },
    },
  },
}));

export default useStyles;
