import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  Container: {
    padding: '15px 100px',
    marginTop: '50px',
    marginBottom: '50px',
    borderRadius: 15,
    backgroundColor: 'white',
    '@media (max-width: 630px)': {
      padding: '9px 62px',
    },
    boxShadow: '2px 2px 14px 0 rgba(0, 26, 48, 0.09)',
    '& .DevContent': {
      '& .ModalImg': {
        '& img': {
          height: 137,
          '@media (max-width: 630px)': {
            height: 50,
          },
          '@media (max-width: 580px)': {
            height: 30,
          },
        },
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& .innerContent': {
        textAlign: 'center',
        '& h1': {
          fontSize: 45,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.22,
          marginTop: 0,
          letterSpacing: 0.75,
          color: '#250f8a',
          '& span': {
            display: 'block',
          },
          '@media (max-width: 630px)': {
            fontSize: 25,
          },
          '@media (max-width: 580px)': {
            fontSize: 20,
          },
          '@media (max-width: 480px)': {
            fontSize: 18,
          },
        },
        '& .devPara1': {
          fontSize: 23,
          color: '#250f8a',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.38,
          marginBottom: 35,
          '@media (max-width: 630px)': {
            fontSize: 15,
          },
          '@media (max-width: 580px)': {
            marginBottom: 20,
            fontSize: 13,
          },
          '@media (max-width: 480px)': {
            fontSize: 12,
          },
          '@media (max-width: 375px)': {
            fontSize: 10,
          },
        },
        '& .devPara2': {
          fontSize: 23,
          color: '#250f8a',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.38,
          marginBottom: 40,
          '& span': {
            display: 'block',
          },
          '@media (max-width: 630px)': {
            fontSize: 13,
          },
          '@media (max-width: 580px)': {
            marginBottom: 30,
          },
          '@media (max-width: 480px)': {
            fontSize: 12,
          },
          '@media (max-width: 375px)': {
            fontSize: 10,
          },
        },
      },
      '& .btn': {
        width: '30%',
        marginBottom: 30,
        '@media (max-width: 959px)': {
          width: '100%',
        },
        '@media (max-width: 630px)': {
          width: '35%',
        },
        '@media (max-width: 580px)': {
          width: '60%',
        },
        '& .MuiButton-label': {
          '@media (max-width: 630px)': {
            fontSize: 15,
          },
          '@media (max-width: 580px)': {
            fontSize: 13,
          },
          '@media (max-width: 530px)': {
            fontSize: 10,
          },
          '@media (max-width: 480px)': {
            fontSize: 8,
          },
          '@media (max-width: 375px)': {
            fontSize: 10,
          },
        },
        '& a': {
          textDecoration: 'none',
        },
      },
    },
  },
}));

export default useStyles;
