import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  Container: {
    backgroundImage: `url("./assests/trace-163.png")`,
    backgroundRepeat: 'no-repeat',
    objectFit: 'contain',
    minHeight: '1470px',
    marginTop: '-476px',
    backgroundSize: 'auto 100%',
    backgroundPosition: 'center',
    '@media (min-width: 1590px)': {
      backgroundSize: '100% 100%',
    },
    '@media (max-width: 1192px)': {
      backgroundSize: '100% auto',
    },
    '@media (max-width: 959px)': {
      backgroundPosition: 'top center',
      marginTop: 0,
    },
    '& .ContentContainer': {
      '& .MainHeading': {
        paddingTop: '37rem',
        '@media (max-width: 959px)': {
          paddingTop: '0',
        },
        '& h1': {
          textAlign: 'center',
          margin: 0,
          color: '#ffffff',
          fontSize: 50,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStyle: 'normal',
          letterSpacing: '0.83px',
          fontStretch: 'normal',
          lineHeight: 'normal',
          '@media (max-width: 820px)': {
            fontSize: 35,
          },
          '@media (max-width: 959px)': {
            color: '#250f8a',
          },
        },
        '& h3': {
          textAlign: 'center',
          margin: 10,
          color: 'white',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontSize: 28,
          fontWeight: 400,
          fontStretch: 'normal',
          lineHeight: 'normal',
          '@media (max-width: 820px)': {
            fontSize: 25,
          },
          '@media (max-width: 959px)': {
            color: '#250f8a',
          },
        },
      },
    },
  },
}));

export default useStyles;
