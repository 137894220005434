import React from 'react';
import Container from '@material-ui/core/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import WebModalStyle from './WebModalStyle';
import Button from '../../../../Button/Button';
import CONSTANTS from '../../../../../constants';

const WebModal = ({ handleClose }) => {
  const classes = WebModalStyle();
  return (
    <Container fixed className={classes.Container}>
      <div className="DevContent">
        <div className="ModalImg">
          <img src={CONSTANTS.url('/assests/path2.png')} alt="" />
        </div>
        <div className="innerContent">
          <h1>
            Developpement
            <span>Web</span>
          </h1>
          <p className="devPara1">
            Développer votre propre solution WEB, Intranet de gestion d'entreprise, E-commerce, CMS
            de gestion, site vitrine, portage de votre application mobile ? Rien n'est trop beau
            pour votre business. Notre équipe réunit les connaissances de plusieurs langages et nous
            saurons nous adapter à vos besoins, présent et même futur.
          </p>
          <p className="devPara2">
            Langage & Framework Web de prédilection :
            <span>JS (FullStack), ReactJS, CSS/HTML, PHP, NodeJS, SQL, NoSQL</span>
          </p>
        </div>
        <div className="btn">
          <AnchorLink href="#CONTACT" onClick={handleClose}>
            <Button title="On en parle ?" backgroundWhite />
          </AnchorLink>
        </div>
      </div>
    </Container>
  );
};

export default WebModal;
