import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import CONSTANTS from '../../../constants';
import SpringModal from '../Specialties/ContentGrid/Modal';
import WebModal2 from './WebModal2/WebModal2';
import AppModal2 from './AppModal2/AppModal2';
import IaModal2 from './IaModal2/IaModal2';

const PortFolioGrid = ({ toggle, toggleMore, title, id, hideButton }) => {
  const [Modal1, setModal1] = React.useState(false);
  const [Modal2, setModal2] = React.useState(false);
  const [Modal3, setModal3] = React.useState(false);

  return (
    <div>
      <SpringModal
        handleClose={() => {
          setModal1(false);
        }}
        open={Modal1}
      >
        <WebModal2 />
      </SpringModal>
      <SpringModal
        handleClose={() => {
          setModal2(false);
        }}
        open={Modal2}
      >
        <AppModal2 />
      </SpringModal>
      <SpringModal
        handleClose={() => {
          setModal3(false);
        }}
        open={Modal3}
      >
        <IaModal2 />
      </SpringModal>
      <Container fixed className="GridContainer">
        <Grid container spacing={3} className="cases" id={id}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            className="image img1"
            onClick={() => {
              setModal1(true);
            }}
          >
            <div className="inner">
              <h1>PMDR</h1>
              <div className="img">
                <img src={CONSTANTS.url('/assests/portFolio2.png')} alt="portFolio2" />
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            className="image img2"
            onClick={() => {
              setModal2(true);
            }}
          >
            <div className="inner">
              <div className="img">
                <img src={CONSTANTS.url('/assests/portfolio1.png')} alt="portfolio1" />
              </div>
              <h1>Pop in Shop</h1>
              {!hideButton && (
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  onClick={() => {
                    toggleMore(!toggle);
                  }}
                >
                  <AnchorLink href={toggle ? '#port1' : '#port2'}>{title}</AnchorLink>
                </Button>
              )}
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            className="image img3"
            onClick={() => {
              setModal3(true);
            }}
          >
            <div className="inner">
              <div className="img">
                <img src={CONSTANTS.url('/assests/portFolio3.png')} alt="portFolio3" />
              </div>
              <h1>I.A. Nexity 2</h1>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} className="back" />
        </Grid>
      </Container>
    </div>
  );
};

export default PortFolioGrid;
