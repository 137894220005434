import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    '&::before': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      content: '""',
      background:
        'url("./assests/path-2.png") left top no-repeat, url("./assests/path-3.png") right 118px  no-repeat',
      top: 0,
      left: 0,
      right: 0,
      margin: 'auto',
    },
    '& .ContentContainer': {
      cursor: 'pointer',
      '& .SubHeading': {
        '& h4': {
          textAlign: 'center',
          fontSize: 25,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.42,
          color: '#250f8a',
          margin: '25px 0px 0px 0px',
        },
        '& p': {
          textAlign: 'center',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          color: '#250f8a',
          fontSize: 20,
          margin: 1,
        },
      },
      '& .team': {
        '& .image': {
          position: 'relative',
          '& img': {
            display: 'block',
            borderRadius: 15,
            transition: '0.1s all ease-in-out',
            '&:hover': {
              boxShadow: '0 0 15px #333',
            },
          },
        },

        '& .description': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(50, 44, 147, 0.8)',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          color: 'white',
          display: 'none',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: 15,
          '& span': {
            display: 'block',
            padding: '10px',
            textAlign: 'center',
            marginTop: '-15px',
          },
          '& h3': {
            marginBottom: 10,
          },
          '& p': {
            margin: 0,
          },
        },
        '& .active': {
          display: 'flex',
          transition: 'width 2s',
        },
      },
    },
  },
  TeamHeading: {
    paddingTop: '5rem',
    '@media (max-width: 800px)': {
      paddingTop: 0,
    },
    '& .Heading': {
      textAlign: 'center',
      '& h1': {
        fontSize: 50,
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.83,
        color: '#250f8a',
        marginBottom: 0,
      },
      '& h3': {
        fontSize: 25,
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 400,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.42,
        color: '#250f8a',
        marginTop: 10,
        marginBottom: 60,
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
