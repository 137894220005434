import React from 'react';
import Container from '@material-ui/core/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MachineModalStyle from './MachineModalStyle';
import Button from '../../../../Button/Button';
import CONSTANTS from '../../../../../constants';

const MachineModal = ({ handleClose }) => {
  const classes = MachineModalStyle();
  return (
    <Container fixed className={classes.Container}>
      <div className="DevContent">
        <div className="ModalImg">
          <img src={CONSTANTS.url('/assests/path.png')} alt="" />
        </div>
        <div className="innerContent">
          <h1>
            Machine
            <span>Learning</span>
          </h1>
          <p className="devPara1">
            Ouvrez votre champ des possibles, grâce au Machine Learning
            <p style={{ alignItem: 'left' }}>
              <br /> Prédire vos prochains achats ?
              <br /> Quelle méthode pour faire vendre plus et quand la mettre en pratique ?
              <br /> Cibler les prospects avec le plus haut taux de transformation et les contacter
              automatiquement ?
            </p>
            <br />
            Le Machine learning est un système en progression constante, ce qui signifie que ses
            algorithmes augmentent, sans cesse, leur efficacité à mesure qu'ils seront alimentés par
            de nouvelles données à traiter. Lorsque la solution est mise en place, après étude des
            cas à traiter, elle assure une croissance exponentielle de tout le système. Plus il y
            aura de données traitées, plus le système sera puissant et les prédictions fiables, le
            tout de façon automatique ! Une fois intégrée a votre Business, et couplée avec une
            solution d'automatisation (Web ou Logiciel) créée par nos soins, la solution se chargera
            d'optimiser en temps réel vos affaires.
          </p>
          <p className="devPara2">
            Technologies de prédilection :
            <span>Machine Learning, Deep Learning, Python, C++, JS, GoLang, Dataiku</span>
          </p>
        </div>
        <div className="btn">
          <AnchorLink href="#CONTACT" onClick={handleClose}>
            <Button title="En savoir plus" />
          </AnchorLink>
        </div>
      </div>
    </Container>
  );
};

export default MachineModal;
