import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    ' & .ContentContainer': {
      '@media (max-width: 1192px)': {
        maxWidth: '100%',
      },
      '@media (max-width: 800px)': {
        textAlign: 'center',
      },
      '& .sideContent': {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '3rem',
        '@media (max-width: 1192px)': {
          paddingTop: '2',
        },
        '@media (max-width: 800px)': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& .image': {
          width: '60%',
          '@media (max-width: 800px)': {
            width: '100%',
          },
          '& img': {
            width: '100%',
          },
        },
        '& .text': {
          width: '40%',
          paddingLeft: 60,
          '@media (max-width: 800px)': {
            width: '100%',
            paddingLeft: 0,
          },
          '& h2': {
            background: '-webkit-linear-gradient(200deg,#fea600,#ef1829)',
            '-webkit-background-clip': 'text',
            ' -webkit-text-fill-color': 'transparent',
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 600,
            fontStyle: 'bold',
            fontSize: 30,
          },
          '& .para1': {
            fontSize: 22,
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 400,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.27,
            letterSpacing: 0.37,
            marginBottom: 50,
            color: '#250f8a',
          },
          '& .para2': {
            fontSize: 22,
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 400,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.27,
            letterSpacing: 0.37,
            color: '#250f8a',
          },
          '& .btn': {
            width: 160,
            height: 50,
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 400,
            backgroundColor: '#250f8a',
            textTransform: 'none',
            fontSize: 20,
            borderRadius: 15,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.42,
            textAlign: 'center',
          },
        },
      },
      '& .Heading': {
        textAlign: 'center',
        '& h1': {
          fontSize: 50,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.83,
          textAlign: 'center',
          color: '#250f8a',
          marginBottom: 0,
          '@media (max-width: 800px)': {
            marginTop: 0,
          },
        },
        '& h3': {
          fontSize: 28,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.47,
          color: '#250f8a',
          marginTop: 8,
          '@media (max-width: 800px)': {
            marginBottom: 0,
          },
        },
      },
    },
  },
}));

export default useStyles;
