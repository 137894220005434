import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '7rem',
    '@media (max-width: 800px)': {
      paddingTop: '3rem',
    },
    '@media (max-width: 1279px)': {
      paddingTop: '3rem',
    },
    '& .MuiGrid-item': {
      padding: 30,
      '@media (max-width: 959px)': {
        padding: 40,
      },
    },
    '& .Gridinner': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      background: 'white',
      height: '100%',
      objectFit: 'contain',
      borderRadius: 15,
      boxShadow: '2px 2px 14px 0 rgba(0, 26, 48, 0.09)',
      padding: '15px 20px',
      cursor: 'pointer',
      '& .button': {
        marginBottom: -50,
      },
      '& .GridImage': {
        display: 'flex',
        justifyContent: 'center',
        '& img': {
          height: '137px',
        },
      },
      '& .GridHeading': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#250f8a',
        '& span': {
          display: 'block',
          textAlign: 'center',
        },
        '& h4': {
          margin: 30,
          fontSize: '28px',
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.47,
          textAlign: 'center',
        },
      },
      '& .innerText': {
        color: 'white',
      },
    },
    '& .background': {
      backgroundImage: ' linear-gradient(53deg, #fea600, #ef1829 92%)',
    },
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
