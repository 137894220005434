import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../../../Button/Button';
import SpringModal from './Modal';
import ContentGridStyle from './ContentGridStyle';
import DevModal from './DevModal/DevModal';
import WebModal from './WebModal/WebModal';
import MachineModal from './MachineModal/MachineModal';
import CONSTANTS from '../../../../constants';

const ContentGrid = () => {
  const classes = ContentGridStyle();

  const [Modal1, setModal1] = React.useState(false);
  const [Modal2, setModal2] = React.useState(false);
  const [Modal3, setModal3] = React.useState(false);

  return (
    <div className={classes.root}>
      <SpringModal
        handleClose={() => {
          setModal1(false);
        }}
        open={Modal1}
      >
        <DevModal
          handleClose={() => {
            setModal1(false);
          }}
        />
      </SpringModal>
      <SpringModal
        handleClose={() => {
          setModal2(false);
        }}
        open={Modal2}
      >
        <WebModal
          handleClose={() => {
            setModal2(false);
          }}
        />
      </SpringModal>
      <SpringModal
        handleClose={() => {
          setModal3(false);
        }}
        open={Modal3}
      >
        <MachineModal
          handleClose={() => {
            setModal3(false);
          }}
        />
      </SpringModal>
      <Grid container spacing={10}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="Gridinner"
            onClick={() => {
              setModal1(true);
            }}
          >
            <div className="GridImage">
              <img src={CONSTANTS.url('/assests/path3.png')} alt="path3" />
            </div>
            <div className="GridHeading">
              <h4>
                Développement
                <span>application</span>
              </h4>
            </div>
            <div className="button">
              <Button title="Prendre RDV" />
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="Gridinner background"
            onClick={() => {
              setModal2(true);
            }}
          >
            <div className="GridImage">
              <img src={CONSTANTS.url('/assests/path2.png')} alt="path2" />
            </div>
            <div className="GridHeading innerText">
              <h4>
                Développement
                <span>Web</span>
              </h4>
            </div>
            <div className="button">
              <Button title="On en parle ?" backgroundWhite />
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="Gridinner"
            onClick={() => {
              setModal3(true);
            }}
          >
            <div className="GridImage">
              <img src={CONSTANTS.url('/assests/path.png')} alt="path" />
            </div>
            <div className="GridHeading">
              <h4>
                Machine
                <span>Learning</span>
              </h4>
            </div>
            <div className="button">
              <Button title="En savoir plus" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContentGrid;
