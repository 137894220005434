import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonStyle from './ButtonStyle';

const ContainedButtons = ({ title, backgroundWhite }) => {
  const classes = ButtonStyle();
  return (
    <div className={classes.root}>
      <Button variant="contained" className={`${backgroundWhite ? 'DevWebBtn' : 'ContentBtn'}`}>
        <span className="text2">{title}</span>
      </Button>
    </div>
  );
};

export default ContainedButtons;
