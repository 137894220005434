import React from 'react';
import Container from '@material-ui/core/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import DevModalStyle from './DevModalStyle';
import Button from '../../../../Button/Button';
import CONSTANTS from '../../../../../constants';

const DevModal = ({ handleClose }) => {
  const classes = DevModalStyle();

  return (
    <Container fixed className={classes.Container}>
      <div className="DevContent">
        <div className="ModalImg">
          <img src={CONSTANTS.url('/assests/path3.png')} alt="" />
        </div>
        <div className="innerContent">
          <h1>
            Développement
            <span>application</span>
          </h1>
          <p className="devPara1">
            Portage de votre solution web ? Ou concept native de votre app ? Cantem vous suit sur
            tous les supports. Dans un monde où 71% de la population mondiale possède un smartphone,
            l’application devient l’outil numéro 1 de n’importe quel projet. Créer votre propre
            application, "From Scratch" ou même, porter votre solution Web déjà existante ! D'une
            application de gestion d'entreprise, de projet, en passant par les apps de shopping
            jusqu'aux solutions les plus complexes et très précises. Cantem saura vous accompagner
            sur chaque étapes de la création de votre App... idéation et création de maquettes
            interactives, étude de cas et développement concret
          </p>
          <p className="devPara2">
            Langage de prédilection :
            <span>React Native, Java Script (Fullstack), iOS (Swift), Android (java)</span>
          </p>
        </div>
        <div className="btn">
          <AnchorLink href="#CONTACT" onClick={handleClose}>
            <Button title="Prendre RDV" />
          </AnchorLink>
        </div>
      </div>
    </Container>
  );
};

export default DevModal;
