import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .ContentBtn': {
      width: '100%',
      margin: 0,
      backgroundImage: ' linear-gradient(80deg, #fea600, #ef1829 94%)',
      borderRadius: 15,
      color: '#ffff',
    },
    '& .MuiButton-label': {
      fontSize: 25,
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 400,
      textTransform: 'none',
    },
    '& .DevWebBtn': {
      width: '100%',
      margin: 0,
      backgroundColor: 'white',
      borderRadius: 15,
      '& span.text2': {
        background: '-webkit-linear-gradient(#fea600, #ef1829 )',
        '-webkit-background-clip': 'text',
        ' -webkit-text-fill-color': 'transparent',
      },
    },
  },
}));

export default useStyles;
