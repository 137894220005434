import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  Container: {
    zIndex: 10,
    padding: '0px 40px',
    objectFit: 'contain',
    borderRadius: 15,
    boxShadow: '2px 2px 14px 0 rgba(0, 26, 48, 0.09)',
    backgroundColor: '#ffffff',
    '@media (max-width: 630px)': {
      padding: 0,
    },
    '& .webContent': {
      padding: '0px 100px',
      '@media (max-width: 630px)': {
        padding: '10px 40px',
      },
      '& .appText': {
        '& p': {
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          fontSize: 18,
          color: '#250f8a',
          margin: '11px 0px 10px 0px',
          '@media (max-width: 630px)': {
            fontSize: 15,
            marginTop: 5,
          },
          '@media (max-width: 580px)': {
            fontSize: 14,
          },
          '@media (max-width: 375px)': {
            fontSize: 12,
          },
        },
        '& .applists': {
          padding: 0,
          textDecoration: 'none',
          listStyle: 'none',
          margin: '9px 0px 0px 0px',
          '& li': {
            fontFamily: 'Source Sans Pro, sans-serif',
            fontWeight: 400,
            color: '#250f8a',
          },
          '@media (max-width: 630px)': {
            fontSize: 15,
            marginTop: 5,
          },
          '@media (max-width: 580px)': {
            fontSize: 14,
          },
          '@media (max-width: 375px)': {
            fontSize: 12,
          },
        },
      },
      '& h1': {
        textAlign: 'center',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontWeight: 600,
        fontSize: 45,
        margin: '0px 0px 20px 0px',
        letterSpacing: 0.75,
        color: '#250f8a',
        '@media (max-width: 630px)': {
          fontSize: 30,
          margin: 0,
        },
        '@media (max-width: 580px)': {
          fontSize: 25,
        },
      },
      '& .imageSlicker': {
        outline: 'none',
        '& .imageWrapper': {
          display: 'flex !important',
          justifyContent: 'center',
          outline: 'none',
          '& img': {
            borderRadius: 15,
            display: 'block',
            width: '24%',
            '@media (max-width: 630px)': {
              width: '50%',
            },
            '@media (max-width: 375px)': {
              width: '40%',
              height: 200,
            },
          },
        },
        '& .slick-next:before, .slick-prev:before': {
          color: '#250f8a',
        },
      },
    },
  },
}));

export default useStyles;
