import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  ContentContainer: {
    paddingTop: '4rem',
    maxWidth: '1200px',
    '@media (max-width: 1192px)': {
      maxWidth: '100%',
    },
    '@media (max-width: 630px)': {
      paddingTop: 0,
    },
    '& .AboutContent': {
      display: 'flex',
      '@media (max-width: 800px)': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .AboutText': {
        paddingRight: 60,
        width: '40%',
        '@media (max-width: 800px)': {
          width: '100%',
          paddingRight: 0,
          textAlign: 'center',
        },
        '& h1': {
          fontSize: 50,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 0.83,
          color: '#250f8a',
          '@media (max-width: 413px)': {
            fontSize: 35,
          },
        },
        '& h3': {
          fontSize: 28,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          background: '-webkit-linear-gradient( 200deg, #fea600, #ef1829 )',
          '-webkit-background-clip': 'text',
          ' -webkit-text-fill-color': 'transparent',
          marginBottom: 0,
          '@media (max-width: 413px)': {
            fontSize: 25,
          },
        },
        '& p': {
          fontSize: 22,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.27,
          letterSpacing: 0.37,
          color: '#250f8a',
        },
      },
      '& .Aboutimage': {
        width: '60%',
        '@media (max-width: 800px)': {
          width: '100%',
        },
        '& img': {
          width: '100%',
        },
      },
    },
  },
}));

export default useStyles;
